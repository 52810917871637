<template>

<span>
    <v-layout mb-4>
      <v-flex xs10 ml-3>
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Setor Banner - Edição</h2>
      </v-flex>
      <v-flex xs2 text-right mr-3>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'bannersector'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
    
    <form-banner-sector :bannersector="bannersector" :update="true"></form-banner-sector>
</span>
        
</template>

<script>
import FormBannerSectorComponent from "./partial/FormBannerSectorComponent";

export default {
  name: "EditBannerComponent",
  created() {
    this.loadBannerSector()
  },
  props: {
    bnnr_sec_id: {
      require: true
    }
  },
  data () {
    return {
      bannersector: {
        bnnr_sec_id: '',
        bnnr_sec_nome: '',
        bnnr_sec_status: false,
      },
    }
  },
  methods: {
    loadBannerSector() {
      this.$store.dispatch('loadBannerSector', this.bnnr_sec_id)
        .then(response => this.bannersector = response)
        .catch(error => {
          this.$swal({
            position: "center",
            icon: "error",
            title: 'Erro',
            text: 'Dados não localizado',
            showConfirmButton: true,
            timer: 6000
          });
        })
    },
  },
  components: {
    formBannerSector: FormBannerSectorComponent
  }
};
</script>

<style scoped>

</style>
